<template>
  <v-container class="pa-0 mx-0" fluid>
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <template v-slot:activator="{ on: dialog, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :color="filtersApplied ? 'orange' : 'msaBlue'"
              class="white--text"
              v-bind="attrs"
              v-on="{ ...tooltip, ...dialog }"
              v-blur
            >
              <v-icon> mdi-filter </v-icon>
            </v-btn>
          </template>
          <span>Filter</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title class="headline msaBlue white--text">
          <v-row>
            <v-col class="grow"> Filters </v-col>
            <v-col class="shrink">
              <v-btn icon dark @click="dialog = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-row>General</v-row>
            <v-row>
              <v-col class="py-0">
                <v-switch
                  v-model="showUnbanned"
                  label="Show Unbanned Emails"
                  class="font-weight-bold"
                  color="msaBlue"
                  dense
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text class="mr-5" @click="cleanFilters()">
            Clear
          </v-btn>
          <v-btn color="msaBlue white--text" @click="applyFilters()">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: 'BlacklistedEmailFilter',
  computed: {
    filtersApplied() {
      return this.showUnbanned;
    },
  },
  data() {
    return {
      showUnbanned: false,
      dialog: false,
    };
  },
  methods: {
    applyFilters() {
      this.$emit('applyFilters', this.showUnbanned);

      this.dialog = false;
    },
    cleanFilters() {
      this.showUnbanned = false;

      this.$emit('applyFilters', this.showUnbanned);

      this.dialog = false;
    },
  },
};
</script>
