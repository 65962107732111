<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-blur
            text
            x-small
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <v-icon color="msaBlue"> mdi-lock-open-variant-outline </v-icon>
          </v-btn>
          <v-btn
            block
            v-blur
            v-else
            class="msaBlue white--text"
            v-bind="attrs"
            v-on="{ ...tooltip, ...dialog }"
          >
            <v-icon color="white" class="pr-2">
              mdi-lock-open-variant-outline
            </v-icon>
            Unban Email
          </v-btn>
        </template>
        <span>Unban Email</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title class="msaBlue white--text">
        Unban &lt;{{ selectedEmail }}&gt;
      </v-card-title>
      <v-card-text class="pt-2">
        <v-row>
          <v-col class="shrink pt-5"> Reason </v-col>
          <v-col>
            <v-text-field
              v-model="reason"
              outlined
              dense
              counter="250"
              maxLength="250"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="msaBlue--text font-weight-bold" @click="close()">
          Cancel
        </v-btn>
        <v-btn class="msaBlue white--text" @click="unbanEmail()">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UnbanEmail',
  props: {
    selectedEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      reason: '',
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    unbanEmail() {
      const params = {
        email: this.selectedEmail,
        reason: this.reason,
        loaderText: 'Saving...',
      };

      const url = 'unban-blacklisted-email?format=json';

      this.$axios
        .post(url, params)
        .then(() => this.close())
        .catch(error => error);
    },
  },
};
</script>

<style></style>
