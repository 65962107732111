<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <SearchBar
          @search="
            search = $event;
            loading = true;
            newsFeeds = [];
            options.page = 1;
          "
        />
      </v-col>
      <v-col class="pb-0" align="right">
        <BlacklistedEmailFilter @applyFilters="showUnbanned = $event" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Blacklisted Emails
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="itemsCount"
            :footer-props="{
              'items-per-page-options': [20, 30, 50],
            }"
            :options.sync="options"
            :items-per-page.sync="perPage"
            :page.sync="page"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="msaBlue--text">
                  {{ item.email }}
                </td>
                <td align="center">
                  {{ item.bannedDate | filterAsLocalDate() }}
                </td>
                <td align="center">
                  <UnbanEmail
                    :selectedEmail="item.email"
                    v-if="item.unbannedDate == ''"
                    @close="reload = !reload"
                    :key="reload"
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table
            :items="items"
            :server-items-length="itemsCount"
            :footer-props="{
              'items-per-page-options': [20, 30, 50],
            }"
            :options.sync="options"
            :items-per-page.sync="perPage"
            :page.sync="page"
            hide-default-header
            mobile-breakpoint="1"
            v-else
          >
            <template v-slot:item="{ item }">
              <v-card class="mb-2">
                <v-card-title class="lightBg msaBlue--text py-2">
                  {{ item.email }}
                </v-card-title>
                <v-card-text class="pt-2">
                  <v-row>
                    <v-col> Banned Date </v-col>
                    <v-col>
                      {{ item.bannedDate | filterAsLocalDate() }}
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <UnbanEmail
                    :selectedEmail="item.email"
                    v-if="item.unbannedDate == ''"
                    @close="reload = !reload"
                    :key="reload"
                  />
                </v-card-actions>
              </v-card>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import SearchBar from '@/components/SearchBar.vue';
import BlacklistedEmailFilter from '@/components/Filters/BlacklistedEmailFilter.vue';
import UnbanEmail from '@/components/BlacklistedEmails/UnbanEmail.vue';
export default {
  name: 'BlacklistedEmails',
  components: {
    SearchBar,
    BlacklistedEmailFilter,
    UnbanEmail,
  },
  watch: {
    options: {
      handler() {
        this.getItems();
      },
    },
    search: function () {
      this.getItems();
    },
    showUnbanned() {
      this.getItems();
    },
  },
  data() {
    return {
      reload: false,
      search: '',
      page: this.$constants.PAGINATION.DEFAULT_PAGE,
      perPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      items: [],
      itemsCount: 0,
      options: { sortDesc: true },
      showUnbanned: false,
      headers: [
        {
          text: 'Email',
          value: 'email',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Banned Date',
          value: 'bannedDate',
          width: '150px',
          align: 'center',
          class: 'lightGrey',
        },
        {
          text: 'Actions',
          align: 'center',
          width: '100px',
          class: 'lightGrey',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getItems() {
      if (this.search == null) {
        this.search = '';
      }
      let params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        sortDesc:
          this.options.sortDesc[0] != undefined
            ? this.options.sortDesc[0]
            : true,
        sortBy:
          this.options.sortBy[0] != undefined
            ? this.options.sortBy[0]
            : 'bannedDate',
        loaderText: 'Loading...',
        showUnbanned: this.showUnbanned,
      };

      let url = 'get-blacklisted-emails?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.items = response.data.items;
          this.itemsCount = response.data.itemsCount;
        })
        .catch((error) => error);
    },
  },
  mounted() {},
};
</script>
