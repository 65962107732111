var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('SearchBar',{on:{"search":function($event){_vm.search = $event;
          _vm.loading = true;
          _vm.newsFeeds = [];
          _vm.options.page = 1;}}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"align":"right"}},[_c('BlacklistedEmailFilter',{on:{"applyFilters":function($event){_vm.showUnbanned = $event}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"msaBlue white--text"},[_vm._v(" Blacklisted Emails ")]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.itemsCount,"footer-props":{
            'items-per-page-options': [20, 30, 50],
          },"options":_vm.options,"items-per-page":_vm.perPage,"page":_vm.page},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticClass:"msaBlue--text"},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',{attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm._f("filterAsLocalDate")(item.bannedDate))+" ")]),_c('td',{attrs:{"align":"center"}},[(item.unbannedDate == '')?_c('UnbanEmail',{key:_vm.reload,attrs:{"selectedEmail":item.email},on:{"close":function($event){_vm.reload = !_vm.reload}}}):_vm._e()],1)])]}}],null,false,4198627626)}):_c('v-data-table',{attrs:{"items":_vm.items,"server-items-length":_vm.itemsCount,"footer-props":{
            'items-per-page-options': [20, 30, 50],
          },"options":_vm.options,"items-per-page":_vm.perPage,"page":_vm.page,"hide-default-header":"","mobile-breakpoint":"1"},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',{staticClass:"lightBg msaBlue--text py-2"},[_vm._v(" "+_vm._s(item.email)+" ")]),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',[_vm._v(" Banned Date ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm._f("filterAsLocalDate")(item.bannedDate))+" ")])],1)],1),_c('v-card-actions',[(item.unbannedDate == '')?_c('UnbanEmail',{key:_vm.reload,attrs:{"selectedEmail":item.email},on:{"close":function($event){_vm.reload = !_vm.reload}}}):_vm._e()],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }